<template>
  <div class="home-body home-4 faq-page">
    <div class="wrapper">
      <!-- nav header -->
      <header class="header-area stick-h2">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="logo floatleft">
                <a href="/" v-if="env === 'personal-pac'">
                  <img
                    alt="PersonalPac - logo"
                    src="../assets/logo/personalpac-logo.svg"
                  />
                </a>
                <a href="/" v-else>
                  <img
                    alt="GiveProudly - logo"
                    src="../assets/logo/giveProudly-log.svg"
                  />
                </a>
              </div>
              <div class="main-menu floatright">
                <nav>
                  <ul>
                    <li>
                      <a href="/" class="remove-link" id="HOME">HOME</a>
                    </li>
                    <li>
                      <a href="/about" class="remove-link" id="ABOUT">ABOUT</a>
                    </li>
                    <li class="active remove-link">
                      <a href="/faq" id="FAQ">FAQ</a>
                    </li>
                    <li>
                      <a href="/contact" class="remove-link" id="CONTACT"
                        >CONTACT</a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      <!-- mobile nav menu -->
      <div class="mobile-menu-area d-block d-lg-none">
        <div class="container">
          <div class="mobile-menu-area">
            <div class="row">
              <div class="col-md-12">
                <div class="mobile-menu text-center py-3">
                  <nav>
                    <a class="remove-link mr-3" href="/" id="HOME">HOME </a>
                    <a class="remove-link mr-3" href="/about" id="ABOUT"
                      >ABOUT
                    </a>
                    <a class="active remove-link mr-3" href="/faq" id="FAQ"
                      >FAQ
                    </a>
                    <a class="remove-link" href="/contact" id="CONTACT"
                      >CONTACT
                    </a>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- mobile-menu-area end -->
      <section class="slider-main-area">
        <div class="main-slider">
          <div class="hero-slides-faq">
            <img
              src="https://images.unsplash.com/photo-1588173926570-8f5dbece16aa?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=720&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzMTUxNjIzMw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1280"
              class="main-image"
              alt=""
              title="#slider-direction-1"
            />
            <div class="container">
              <div class="inner-container container-xl">
                <div class="slide-title">
                  <div class="section-title">
                    <h1 class="title6">FREQUENTLY ASKED QUESTIONS</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="tab-accordion-area bg-off-white ptb-80">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="tab-content fix">
                <div class="tab-pane active" id="preview">
                  <div class="panel-group" id="accordionContainer">
                    <!-- populate the fas from API here -->
                  </div>
                  <div id="no-data-msg" class="no-data-msg"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="apiLoader"
          class="d-flex flex-row align-items-center justify-content-center mx-auto w-100 my-5"
        >
          <div class="loader"></div>
        </div>
      </div>
      <!-- footer section -->
      <footer id="footer" class="social-area">
        <div class="footer-top">
          <div class="container-lg">
            <div class="row">
              <div
                class="d-flex flex-row align-items-center justify-content-start col-lg-6 col-md-6 col-sm-6 col-12"
              >
                <h4 class="social-follow-title">Follow Us</h4>
              </div>
              <div
                class="d-flex flex-row align-items-center justify-content-center justify-content-sm-end col-lg-6 col-md-6 col-sm-6 col-12"
              >
                <div class="social-icon floatright">
                  <ul>
                    <li>
                      <a
                        class="twitter"
                        target="_blank"
                        href="https://twitter.com/YourPersonalPAC"
                      >
                        <i class="fa fa-twitter-square" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        class="facebook"
                        target="_blank"
                        href="https://www.facebook.com/ThePersonalPACProject"
                      >
                        <i class="fa fa-facebook-square" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        class="youtube"
                        target="_blank"
                        href="https://www.youtube.com/channel/UCAuoNhsEtra6ACv8leeEexg"
                      >
                        <i class="fa fa-youtube-square" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-middle ptb-40">
          <div class="container-lg">
            <div class="row">
              <div class="col-lg-3 col-md-6">
                <div class="middle-text">
                  <div class="social-logo">
                    <a href="/">
                      <img
                        v-if="env === 'personal-pac'"
                        src="../assets/logo/personalpac-white-logo.svg"
                        alt=""
                      />
                      <img
                        v-else
                        src="../assets/logo/giveproudly-logo-white.png"
                        alt=""
                        class="logo-size"
                      />
                    </a>
                  </div>
                  <div class="middle-mgn"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="container-lg">
            <div class="footer-bottom-all">
              <div class="row">
                <div
                  class="d-flex flex-row align-items-center justify-content-center justify-content-sm-start col-12 col-sm-7 col-lg-6"
                >
                  <div class="footer-menu">
                    <ul>
                      <li>
                        <a href="/" class="remove-link" id="FOOTER-HOME"
                          >HOME</a
                        >
                      </li>
                      <li>
                        <a href="/about" class="remove-link" id="FOOTER-ABOUT"
                          >ABOUT</a
                        >
                      </li>
                      <li>
                        <a
                          href="/faq"
                          class="active remove-link"
                          id="FOOTER-FAQ"
                          >FAQ</a
                        >
                      </li>
                      <li>
                        <a
                          href="/contact"
                          class="remove-link"
                          id="FOOTER-CONTACT"
                          >CONTACT</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="d-flex flex-row align-items-center justify-content-center justify-content-sm-end col-12 col-sm-5 col-lg-6"
                >
                  <div class="footer-menu right-side-menu">
                    <ul>
                      <li>
                        <a
                          href="/privacy"
                          class="remove-link"
                          id="FOOTER-PRIVACY"
                          >PRIVACY</a
                        >
                      </li>
                      <li>
                        <a href="/terms" class="remove-link" id="FOOTER-TC"
                          >T&amp;C</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<!-- jquery -->
<script src="js/vendor/jquery-1.12.4.min.js"></script>
<!-- Popper JS -->
<script src="js/popper.js"></script>
<!-- bootstrap JS -->
<script src="js/bootstrap.min.js"></script>
<!-- wow JS -->
<script src="js/wow.min.js"></script>
<!-- plugins JS -->
<script src="js/plugins.js"></script>
<!-- main JS -->
<script src="js/main.js"></script>
<!-- custom JS -->
<script>
import "@/assets/styles/style.css";
import "@/assets/styles/animate.css";
import "@/assets/styles/bootstrap.min.css";
// import "@/assets/styles/chosen.min.css";
import "@/assets/styles/font-awesome.min.css";
import "@/assets/styles/magnific-popup.css";
import "@/assets/styles/main.css";
import "@/assets/styles/meanmenu.min.css";
import "@/assets/styles/normalize.css";
import "@/assets/styles/responsive.css";
// import "@/assets/styles/style-customizer.css";
import "@/assets/styles/color-custom.css";

export default {
  data() {
    return {
      env: null,
    };
  },
  mounted() {
    this.env = process.env.VUE_APP_NAME;
  },
};
let accordianContainerIdName = "accordionContainer";

const faqContentUrl = `${process.env.VUE_APP_API_URL}/content/faq`;

fetch(faqContentUrl)
  .then((resp) => resp.json())
  .then(function (data) {
    if (data.code == 200 && data.message == "success") {
      let result = data.content;

      result.forEach(function (item, index) {
        // define the faq panel div element with attributes
        let faqPanel = document.createElement("div");
        faqPanel.className = "panel single-accordion";

        // define the faq title anchor element with attributes
        let faqTitle = document.createElement("div");
        faqTitle.className = "accordion-head";
        // href should refer to the description container's id
        faqTitle.setAttribute("href", `#accordian-${index}`);
        faqTitle.setAttribute("data-bs-toggle", "collapse");
        // set the inner text for the faq title
        faqTitle.innerText = item.title;

        // put the faq title anchor element inside the panel div
        faqPanel.appendChild(faqTitle);

        let faqDesContainer = document.createElement("div");
        faqDesContainer.className = "collapse";
        faqDesContainer.id = `accordian-${index}`;
        faqDesContainer.setAttribute(
          "data-bs-parent",
          `#${accordianContainerIdName}`
        );

        // set the innerHTML of the faq description container
        faqDesContainer.innerHTML = `
                        <div class="accordion-body fix">
                            <p id="faq-des">${item.description}</p>
                        </div>
                        `;

        // append the faq description container into the faq panel div
        faqPanel.appendChild(faqDesContainer);
        // append the faq panel div into the accordian container
        document.getElementById(accordianContainerIdName).appendChild(faqPanel);
        // hide loader after API is processed
        document.getElementById("apiLoader").className = "d-none";
      });
      // no data msg
      if (result.length === 0) {
        document.getElementById("no-data-msg").innerHTML = "No data to show!";
        // hide loader after API is processed
        document.getElementById("apiLoader").className = "d-none";
      }
    }
  });
</script>
<style scoped>
.logo-size {
  object-fit: cover;
  height: 200px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
.remove-link {
  text-decoration: none;
  color: white;
  border: none !important;
}
.mobile-menu {
  height: 60px;
}
.active {
  color: black;
  text-decoration: none;
}
</style>
