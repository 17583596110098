var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-body home-4 faq-page"},[_c('div',{staticClass:"wrapper"},[_c('header',{staticClass:"header-area stick-h2"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"logo floatleft"},[(_vm.env === 'personal-pac')?_c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"alt":"PersonalPac - logo","src":require("../assets/logo/personalpac-logo.svg")}})]):_c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"alt":"GiveProudly - logo","src":require("../assets/logo/giveProudly-log.svg")}})])]),_vm._m(0)])])])]),_vm._m(1),_vm._m(2),_vm._m(3),_c('footer',{staticClass:"social-area",attrs:{"id":"footer"}},[_vm._m(4),_c('div',{staticClass:"footer-middle ptb-40"},[_c('div',{staticClass:"container-lg"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-6"},[_c('div',{staticClass:"middle-text"},[_c('div',{staticClass:"social-logo"},[_c('a',{attrs:{"href":"/"}},[(_vm.env === 'personal-pac')?_c('img',{attrs:{"src":require("../assets/logo/personalpac-white-logo.svg"),"alt":""}}):_c('img',{staticClass:"logo-size",attrs:{"src":require("../assets/logo/giveproudly-logo-white.png"),"alt":""}})])]),_c('div',{staticClass:"middle-mgn"})])])])])]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-menu floatright"},[_c('nav',[_c('ul',[_c('li',[_c('a',{staticClass:"remove-link",attrs:{"href":"/","id":"HOME"}},[_vm._v("HOME")])]),_c('li',[_c('a',{staticClass:"remove-link",attrs:{"href":"/about","id":"ABOUT"}},[_vm._v("ABOUT")])]),_c('li',{staticClass:"active remove-link"},[_c('a',{attrs:{"href":"/faq","id":"FAQ"}},[_vm._v("FAQ")])]),_c('li',[_c('a',{staticClass:"remove-link",attrs:{"href":"/contact","id":"CONTACT"}},[_vm._v("CONTACT")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-menu-area d-block d-lg-none"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"mobile-menu-area"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"mobile-menu text-center py-3"},[_c('nav',[_c('a',{staticClass:"remove-link mr-3",attrs:{"href":"/","id":"HOME"}},[_vm._v("HOME ")]),_c('a',{staticClass:"remove-link mr-3",attrs:{"href":"/about","id":"ABOUT"}},[_vm._v("ABOUT ")]),_c('a',{staticClass:"active remove-link mr-3",attrs:{"href":"/faq","id":"FAQ"}},[_vm._v("FAQ ")]),_c('a',{staticClass:"remove-link",attrs:{"href":"/contact","id":"CONTACT"}},[_vm._v("CONTACT ")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"slider-main-area"},[_c('div',{staticClass:"main-slider"},[_c('div',{staticClass:"hero-slides-faq"},[_c('img',{staticClass:"main-image",attrs:{"src":"https://images.unsplash.com/photo-1588173926570-8f5dbece16aa?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=720&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzMTUxNjIzMw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1280","alt":"","title":"#slider-direction-1"}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"inner-container container-xl"},[_c('div',{staticClass:"slide-title"},[_c('div',{staticClass:"section-title"},[_c('h1',{staticClass:"title6"},[_vm._v("FREQUENTLY ASKED QUESTIONS")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-accordion-area bg-off-white ptb-80"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"tab-content fix"},[_c('div',{staticClass:"tab-pane active",attrs:{"id":"preview"}},[_c('div',{staticClass:"panel-group",attrs:{"id":"accordionContainer"}}),_c('div',{staticClass:"no-data-msg",attrs:{"id":"no-data-msg"}})])])])])]),_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-center mx-auto w-100 my-5",attrs:{"id":"apiLoader"}},[_c('div',{staticClass:"loader"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-top"},[_c('div',{staticClass:"container-lg"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-start col-lg-6 col-md-6 col-sm-6 col-12"},[_c('h4',{staticClass:"social-follow-title"},[_vm._v("Follow Us")])]),_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-center justify-content-sm-end col-lg-6 col-md-6 col-sm-6 col-12"},[_c('div',{staticClass:"social-icon floatright"},[_c('ul',[_c('li',[_c('a',{staticClass:"twitter",attrs:{"target":"_blank","href":"https://twitter.com/YourPersonalPAC"}},[_c('i',{staticClass:"fa fa-twitter-square",attrs:{"aria-hidden":"true"}})])]),_c('li',[_c('a',{staticClass:"facebook",attrs:{"target":"_blank","href":"https://www.facebook.com/ThePersonalPACProject"}},[_c('i',{staticClass:"fa fa-facebook-square",attrs:{"aria-hidden":"true"}})])]),_c('li',[_c('a',{staticClass:"youtube",attrs:{"target":"_blank","href":"https://www.youtube.com/channel/UCAuoNhsEtra6ACv8leeEexg"}},[_c('i',{staticClass:"fa fa-youtube-square",attrs:{"aria-hidden":"true"}})])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-bottom"},[_c('div',{staticClass:"container-lg"},[_c('div',{staticClass:"footer-bottom-all"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-center justify-content-sm-start col-12 col-sm-7 col-lg-6"},[_c('div',{staticClass:"footer-menu"},[_c('ul',[_c('li',[_c('a',{staticClass:"remove-link",attrs:{"href":"/","id":"FOOTER-HOME"}},[_vm._v("HOME")])]),_c('li',[_c('a',{staticClass:"remove-link",attrs:{"href":"/about","id":"FOOTER-ABOUT"}},[_vm._v("ABOUT")])]),_c('li',[_c('a',{staticClass:"active remove-link",attrs:{"href":"/faq","id":"FOOTER-FAQ"}},[_vm._v("FAQ")])]),_c('li',[_c('a',{staticClass:"remove-link",attrs:{"href":"/contact","id":"FOOTER-CONTACT"}},[_vm._v("CONTACT")])])])])]),_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-center justify-content-sm-end col-12 col-sm-5 col-lg-6"},[_c('div',{staticClass:"footer-menu right-side-menu"},[_c('ul',[_c('li',[_c('a',{staticClass:"remove-link",attrs:{"href":"/privacy","id":"FOOTER-PRIVACY"}},[_vm._v("PRIVACY")])]),_c('li',[_c('a',{staticClass:"remove-link",attrs:{"href":"/terms","id":"FOOTER-TC"}},[_vm._v("T&C")])])])])])])])])])
}]

export { render, staticRenderFns }